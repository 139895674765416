<template>
  <div class="container" :class="!iframeUrl?'bg':''">
    <iframe :src="iframeUrl" frameborder="0"></iframe>
    <headers :titles="titles"></headers>
    <div class="column392">
      <div class="column-item date">
        <times></times>
        <div class="btn" @click="gotoHome">首 页</div>
      </div>
      <div class="column-item" style="height:280px">
        <div class="column-item-title title-bg">
          <h4>人口统计（人）</h4>
        </div>
        <div class="monitor">
          <div class="monitor-item">
            <div class="title">总人口</div>
            <div class="font-num num">{{baseInfo.totalPopulation}}</div>
          </div>
          <div class="monitor-item">
            <div class="title">当地居民</div>
            <div class="font-num num">{{baseInfo.localResidents}}</div>
          </div>
          <div class="monitor-item">
            <div class="title">外来人口</div>
            <div class="font-num num">{{baseInfo.externalPopulation}}</div>
          </div>
          <div class="monitor-item">
            <div class="title">低保人群</div>
            <div class="font-num num">{{baseInfo.totalMinimum}}</div>
          </div>
          <div class="monitor-item">
            <div class="title">党员人数</div>
            <div class="font-num num">{{baseInfo.totalParty}}</div>
          </div>
          <div class="monitor-item">
            <div class="title">致富带头人</div>
            <div class="font-num num">{{baseInfo.richLeader}}</div>
          </div>
        </div>
      </div>
      <div class="column-item" style="height:280px">
        <div class="column-item-title title-bg">
          <h4>产业统计</h4>
        </div>
        <div class="charts">
          <v-chart :options="optionpie" />
        </div>
      </div>
      <div class="column-item" style="height:280px">
        <div class="column-item-title title-bg">
          <h4>网络零售额</h4>
        </div>
        <div class="charts">
          <v-chart :options="optionline" />
        </div>
      </div>
    </div>
    <div class="column392">
      <div class="column-item btn-box" style="background:transparent">
        <div class="btn">直播数据</div>
        <div class="btn">溯源数据</div>
      </div>
      <div class="column-item" style="height:280px">
        <div class="column-item-title title-bg cursor">
          <h4 @click="showProduct('any')">产业村介绍</h4>
        </div>
        <div class="introduce">
          <h5><i></i>{{cunInfo.name}}</h5>
          <p>简介：{{cunInfo.brief}}</p>
        </div>
      </div>
      <div class="column-item" style="height:300px">
        <div class="column-item-title title-bg">
          <h4>创业带头人</h4>
        </div>
        <div class="card">
          <el-carousel trigger="click" height="220px" arrow="never" :interval="6000" @change="(index) => leaderIndex = index">
            <el-carousel-item v-for="item in yjvillageleaderList" :key="item.id">
              <div class="card-item">
                <div class="card-img cursor" @click="showProduct('data')"><img :src="item.images[0]" alt=""></div>
                <div class="card-text">
                  <h5>{{item.name}}</h5>
                  <p>{{item.introduce}}</p>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="column-item">
        <div class="column-item-title title-bg">
          <h4>产业品牌展示</h4>
        </div>
        <el-carousel trigger="click" height="280px" arrow="never" :interval="6000">
          <el-carousel-item v-for="item in yjvillagebrandList" :key="item.id">
            <div class="brand1" @click="() => openNew(item.link)">
              <div class="brand-item">
                <img :src="item.logo" alt="">
                <h6>{{item.name}}</h6>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="popup-box" v-if="showPopup">
      <div class="close" @click="close"></div>
      <div class="popup-title">{{popupTitle}}</div>
      <div class="popup-content">
        <div class="avatar"><img :src="popupTitle === '产业村介绍' ? cunInfo.logo : yjvillageleaderList[currentIndex].images[0]" width="198" height="211" alt=""></div>
        <div class="product-text">
          <h5>{{ popupTitle === '产业村介绍' ? cunInfo.name : yjvillageleaderList[currentIndex].name }}</h5>
          <p>简介：{{ popupTitle === '产业村介绍' ? cunInfo.details : yjvillageleaderList[currentIndex].introduce }}</p>
        </div>
      </div>
      <div class="bottom-img" v-if="popupTitle === '产业村介绍'">
        <div class="img-item" v-for="item in cunInfo.images" :key="item">
          <img :src="item" alt="" />
        </div>
      </div>
      <div class="bottom-img" v-else>
        <div class="img-item" v-for="item in yjvillageleaderList[currentIndex].images" :key="item">
          <img :src="item" alt="" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import headers from "@/components/header.vue";
  import times from "@/components/time.vue";
  import ECharts from "vue-echarts";
  import "echarts/lib/chart/line";
  import "echarts/lib/chart/bar";
// import { threadId } from "worker_threads";
  export default {
    name: 'Cultivation',
    components: {
      headers,
      times,
      "v-chart": ECharts
    },
    data() {
      return {
        iframeUrl:'',
        popupTitle: '',
        popupName: '',
        titles: '电商产业村及品牌培育大数据',
        showPopup: false,
        baseInfo: {
          externalPopulation:'',
          localResidents:'',
          richLeader:'',
          totalMinimum:'',
          totalParty:'',
          totalPopulation: ''
        },
        optionline: {},
        cunInfo: {},
        cunInfo: {},
        leaderIndex: 0,
        currentIndex: 0,
        yjvillageleaderList: [],
        yjvillagebrandList: [],
        //饼图
        optionpie: {},
      };
    },
    mounted() { 
      if(this.$route.query.id==1){
        this.iframeUrl='https://720yun.com/t/ccvkb7rbd7l?scene_id=93070317'
      }else  if(this.$route.query.id==2){
        this.iframeUrl='https://720yun.com/t/b3vkb7rbd77?scene_id=93070388'
      }else if(this.$route.query.id==3){ // 韩埠村
        this.iframeUrl = 'https://www.720yun.com/t/f5vk6wrm0r7?scene_id=103749805'
      }else if(this.$route.query.id==4){ // 巽宅
        this.iframeUrl = 'https://www.720yun.com/t/53vk6wrm0rl?scene_id=103749767'
      } 
    
      this.getyjvillagepopulation()
      this.getyjvillageindustry() //  产业统计
      this.getyjvillageretail() // 网络零售额
      this.getInfo()
      this.getyjvillageleader() //创业带头人
      this.getyjvillagebrand() //产业品牌
    },
    methods: {
      close() {
        this.showPopup = false
      },
      getInfo() {
        const id = this.$route.query.id
        this.$api.get(`/yongjia-biz/yjvillage/${id}`)
          .then(({ data }) => {
            this.cunInfo= data || {}
          })
      },
      getyjvillageleader() {
        const id = this.$route.query.id
        this.$api.get(`/yongjia-biz/yjvillageleader/page?current=1&size=20&villageId=${id}`)
         .then(({ data }) => {
           this.yjvillageleaderList = data.records || []
         })
      },
      getyjvillagebrand() {
        const id = this.$route.query.id
        this.$api.get(`/yongjia-biz/yjvillagebrand/page?current=1&size=20&villageId=${id}`)
         .then(({ data }) => {
           this.yjvillagebrandList = data.records || []
         })
      },
      getyjvillagepopulation() {
        const id = this.$route.query.id
        this.$api.get(`/yongjia-biz/yjvillagepopulation/getListByVillage/${id}`)
         .then(({ data }) => {
           this.baseInfo = (data || [])[0] || {}
         })
      },
      getyjvillageindustry() {
        const id = this.$route.query.id
        this.$api.get(`/yongjia-biz/yjvillageindustry/page?current=1&size=6&villageId=${id}`)
         .then(({ data }) => {
           const list = data.records || []
           this.optionpie = {
             tooltip: {
               trigger: "item",
               formatter: "{a} <br/>{b}: {c} ({d}%)",
             },

             graphic: {
               //图形中间图片
               elements: [{
                 type: "image",
                 style: {
                   image: require("../assets/images/index/pie-r.png"), //你的图片地址
                   width: 83,
                   height: 88,
                 },

                 left: "19.5%",
                 top: "30%",
               }, ],
             },

             legend: {
               orient: "vertical",
               top: "30",
               right: "10%",
               icon: "path://M778,373.28570556640625A116.42856,116.42856,0,1,0,894.4285888671875,489.71429443359375A116.42859,116.42859,0,0,0,778,373.28570556640625Zm0,198.7559814453125a82.32743,82.32743,0,1,1,82.32745361328125,-82.32736206054688A82.32744,82.32744,0,0,1,778,572.0416870117188Z",
               textStyle: {
                 //图例文字的样式
                 color: "#fff",
                 fontSize: 14,
               },
               formatter: function (name) {
                 var index = 0;
                 var clientlabels = list.map(item => ({
                   value: item.area,
                   name: item.produce
                 }));
                 clientlabels.forEach(function (value, i) {
                   if (value.name == name) {
                     index = i;
                   }
                 });
                 return name + "       " + clientlabels[index].value;
               },
             },
             series: [{
               name: "产业统计",
               type: "pie",
               radius: ["50%", "70%"],
               center: ["30%", "50%"],
               avoidLabelOverlap: false,
               label: {
                 show: false,
                 position: "center",
               },
               emphasis: {},
               itemStyle: {
                 //通常情况下：
                 normal: {
                   color: function (params) {
                     var colorList = [
                       ["#24fc2d", "#000000"],
                       ["#2262bf", "#000000"],
                       ["#7647c2", "#000000"],
                       ["#a4233d", "#000000"],
                       ["#964e14", "#000000"],
                       ["#d9c717", "#000000"],
                       ["#47c3ce", "#000000"],
                     ];
                     var colorItem = colorList[params.dataIndex];
                     return new ECharts.graphic.LinearGradient(
                       0,
                       0,
                       0,
                       1,
                       [{
                           offset: 0,
                           color: colorItem[0],
                         },
                         {
                           offset: 1,
                           color: colorItem[1],
                         },
                       ],
                       false
                     );
                   },
                 },
               },

               labelLine: {
                 show: false,
               },
               data: list.map(item => ({
                 value: item.area,
                 name: item.produce
               }))
             }, ],
           }
         })
      },
      getyjvillageretail() {
        const id = this.$route.query.id
        const year = new Date().getFullYear() + '-01-01 00:00:00'
        const lastYear = (new Date().getFullYear() - 1) + '-01-01 00:00:00'
        this.$api.get(`/yongjia-biz/yjvillageretail/page?current=1&size=1&villageId=${id}&year=${year}`)
         .then(({ data }) => {
           this.$api.get(`/yongjia-biz/yjvillageretail/page?current=1&size=1&villageId=${id}&year=${lastYear}`)
            .then(res => {
              
              const result = (data.records || [])[0] || {}
              const { january, february, march, april, may, june, july, october, september, august, november, december } = result
              const currentData = [+january, +february, +march, +april, +may, +june, +july, +august, +september, +october, +november, +december]
              const lastYearResult = (res.data.records || [])[0] || {}
              const lastYearData = [+lastYearResult.january, +lastYearResult.february, +lastYearResult.march, +lastYearResult.april, +lastYearResult.may, +lastYearResult.june, +lastYearResult.july, +lastYearResult.august, +lastYearResult.september, +lastYearResult.october, +lastYearResult.november, +lastYearResult.december]
              this.optionline = {
                grid: {
                  top: '15%', //距上边距
                  left: '10%', //距离左边距
                  right: '10%', //距离右边距
                  bottom: "15%", //距离下边距
                },
                legend: {
                  right: "10%",
                  icon: "roundRect",
                  textStyle: {
                    //图例文字的样式
                    color: "#fff",
                    fontSize: 15,
                  },
                },
                xAxis: [{
                  type: "category",
                  data: [
                    "1月",
                    "2月",
                    "3月",
                    "4月",
                    "5月",
                    "6月",
                    "7月",
                    "8月",
                    "9月",
                    "10月",
                    "11月",
                    "12月",
                  ],
                  // 修改文本的颜色
                  axisLabel: {
                    color: "#556f95",
                    fontSize: 10,
                  },
                  // 修改轴线的颜色
                }, ],
                yAxis: [{
                  type: "value",
                  axisLabel: {
                    color: "#556f95",
                    fontSize: 10,
                  },
                  // 修改y轴横向分割线的颜色
                  splitLine: {
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                      color: ["#5f666a"],
                    },
                  },
                }, ],
                series: [{
                    name: "去年",
                    type: "line",
                    smooth: true,
                    color: ["#CBB012"],
                    lineStyle: {
                      width: 5,
                    },
                    data: lastYearData
                  },
                  {
                    name: "今年",
                    type: "line",
                    smooth: true,
                    color: ["#46F9FD"],
                    lineStyle: {
                      width: 5,
                    },
                    data: currentData
                  },
                ],
              }
            })
         })
      },
      showProduct(name) {
        if (name === 'data') {
          this.popupTitle = '创业带头人介绍'
          this.popupName = '贾荣伟'
          this.currentIndex = this.leaderIndex
        } else {
          this.popupTitle = '产业村介绍'
          this.popupName = 'xx电商产业村'
        }
        this.showPopup = true
      },
      gotoHome() {
        this.$router.push({
          path: '/'
        })
      },
      openNew(link) {
        window.open(link, '_blank')
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../assets/css/pubilc.css';
  @import '../assets/css/font.css';
iframe{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}
.bg{
    background: url('../assets/images/index/bg.png') no-repeat center #000;

}
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1920px;
    height: 1080px;
    padding: 0 30px;
    box-sizing: border-box;

    .header {
      width: 1917px;
      height: 167px;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -958px;
      z-index: 0;
      background: url('../assets/images/index/header-bg.png') no-repeat center;

      h1 {
        font-size: 40px;
        font-family: PangMenZhengDao;
        font-weight: bold;
        color: #FFFFFF;
        margin-top: 32px;
        text-align: center;
        text-shadow: 0 0 0.2em #fff,
          -0 -0 0.2em #065abe;
      }
    }

    .footer {
      width: 1075px;
      height: 59px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -537px;
      z-index: 0;
      background: url('../assets/images/index/footer-bg.png') no-repeat;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 30px;
        font-family: PangMenZhengDao;
        font-weight: 400;
        color: #FFFFFF;
        text-shadow: 0 0 0.2em #fff,
          -0 -0 0.2em #065abe;
      }
    }
  }

  .column392 {
    width: 392px;
    margin-top: 75px;
    margin-right: 30px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 80px;
  }

  .column-item {
    width: 100%;
    background: url(../assets/images/index/bg01.png) no-repeat;
    background-size: 100% 100%;
    margin-bottom:15px;
    max-height: 320px;


    .column-item-title {
      width: 100%;
      height: 60px;

      h4 {
        line-height: 60px;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
      }
    }

    .colour {
      tbody {
        tr {
          &:nth-child(1) {
            .num {
              color: #E5772E;
            }

          }

          &:nth-child(2) {
            .num {
              color: #F19E6C;
            }
          }

          &:nth-child(3) {
            .num {
              color: #F1CD6C;
            }
          }
        }
      }
    }

    .ranking {
      width: 100%;

      tr {
        height: 33px;
        line-height: 33px;

        &:nth-child(even) {
          background: url('../assets/images/index/hover-bg.png') no-repeat center;
        }
      }

      th {
        font-size: 20px;
        font-weight: bold;
        color: #556F95;
      }

      td {
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 20px;
      }
    }

    .ranking1 {
      width: 100%;
      height: 360px;
      margin: 20px 0;

      tr {
        height: 33px;
        line-height: 33px;

        &:nth-child(even) {
          background: url('../assets/images/index/hover-bg.png') no-repeat center;
        }
      }

      th {
        font-size: 20px;
        font-weight: bold;
        color: #556F95;
      }

      td {
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 20px;
      }
    }
  }

  .date {
    background: url('../assets/images/index/date-icon.png') no-repeat left center;
    height: 28px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
  }

  .charts1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;

    div {
      margin-top: 19px;
    }

    .num {
      font-size: 24px;
      font-weight: bold;
      color: #FFFFFF;
    }

    .charts1-item-title {
      font-size: 14px;
      font-weight: bold;
      color: #64CDF9;
      opacity: 0.75;
    }
  }

  .charts2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;

    .charts2-item {
      width: 22%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      div {
        .num {
          font-size: 28px;
          font-weight: bold;
          color: #00E4FF;

          span {
            font-size: 18px;
            font-weight: bold;
            color: #00E4FF;
          }
        }

        .charts1-item-title {
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
        }
      }
    }


  }

  .btn-box {
    height: 44px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .btn{
     background: url(../assets/images/index/bg5.png) no-repeat;
    background-size: 100% 100%;
  }


  .column850 {
    width: 850px;
    position: absolute;
    left: 50%;
    bottom: 100px;
    margin-left: -425px;
  }

  .column1000 {
    width: 1000px;
    position: absolute;
    left: 50%;
    top: 130px;
    margin-left: -500px;
  }

  .column1006 {
    width: 1006px;
    position: absolute;
    left: 50%;
    top: 258px;
    margin-left: -503px;
  }

  .monitor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;

    .monitor-item {
      width: 33%;
      margin: 20px 0;

      .num {
        font-size: 26px;
        font-weight: bold;

        span {
          font-size: 12px;
          font-weight: normal;
        }
      }

      .title {
        font-size: 14px;
        font-weight: bold;
        color: #64CDF9;
        opacity: 0.75;
      }
    }
  }

  .card {
    padding: 0 10px;
    margin-top: 20px;

    .card-item {
      display: flex;
      flex-direction: row;
      text-align: left;

      .card-img {
        margin-right: 22px;
        width: 120px;
        height: 168px;
        background: #FEFEFE;
        border-radius: 6px;
        img {
          width: 100%;
          border-radius: 6px;
          height: 100%;
        }
      }

      .card-text {
        flex: 1;
        height: fit-content;
        max-height: 168px;
        overflow-y:auto;
        h5 {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 16px;
          color: #fff;
        }

        p {
          font-size: 16px;
          font-weight: bold;
          line-height: 150%;
        }
      }
    }
  }

  // 图标切换效果 修改指示器样式

  ::v-deep .el-carousel__button {
    background: url('../assets/images/index/button-icon.png') no-repeat center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }

  .video {
    height: 723px;
  }

  .video-player {
    height: 723px;

    .vjs-icon-placeholder {
      width: 72px;
      height: 72px;
      border-radius: 100%;
      z-index: 100;
      background-color: #ffffff;
      border: solid 1px #979797;
    }
  }

  .introduce {
    padding: 0 20px;

    h5 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 24px;
      font-weight: bold;
      margin: 30px 0;
      color: #fff;

      i {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #2262BF;
        margin-right: 20px;
      }
    }

    p {
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      line-height: 180%;
      margin-bottom: 20px;
      overflow: auto;
      height: 113px;
    }
  }

  .link {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;


    .link-item {
      transition: 1s;
      -webkit-transition: 1s;

      &:hover {
        transform: rotate3d(0, 1, 0, 180deg);
      }
    }


  }

  .charts {
    width: 100%;
    height: 220px;
    overflow: hidden;

    .echarts {
      width: 100%;
      height: 220px;
    }
  }

  .brand1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;

    .brand-item {
      width: 280px;

      img {
        margin-bottom: 12px;
        width: 279px;
        height: 157px;
        border-radius: 6px;

        &:hover {
          cursor: pointer;
        }
      }

      h6,
      p {
        font-size: 14px;
        font-weight: bold;
        color: #64CDF9;
        opacity: 0.7;
        line-height: 24px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .popup-box {
    position: absolute;
    top: 150px;
    left: 50%;
    margin-left: -403px;
    width: 806px;
    height: 885px;
    border-radius: 20px;
    padding: 30px 50px;
    text-align: left;
    background: rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 5px 0 #87fcfe;

    .close {
      width: 17px;
      height: 17px;
      background: url('../assets/images/index/close.png') no-repeat;
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 9999;
      transition: 1s;
      -webkit-transition: 1s;

      &:hover {
        cursor: pointer;
        transform: rotate(180deg);
      }
    }

    .popup-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 30px;
    }

    .popup-content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 40px;

      .avatar {
        width: 198px;
        height: 211px;
        margin-right: 30px;
      }

      .product-text {
        text-align: left;
        height: fit-content;
        max-height: 520px;
        overflow-y:auto;

        h5 {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
          color: #fff;
        }

        p {
          text-align: left;
          font-size: 16px;
          font-weight: bold;
          line-height: 180%;
        }
      }
    }

    .bottom-img {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .img-item {
        width: 200px;
        height: 159px;
        border-radius: 10px;
        background: #fff;
        img {
          width: 200px;
          border-radius: 10px;
          height: 159px;
        }
      }
    }
  }
</style>
